import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cc-dropdown-divider',
  standalone: true,
  imports: [],
  templateUrl: './dropdown-divider.component.html',
  styleUrls: ['./dropdown-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownDividerComponent {}
